<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import {sendDot} from '@/js/dot'
export default {
  name: 'App',
  mounted() {
    // 在组件挂载后执行的逻辑
    // 可以在这里进行登录状态检查等操作
    console.log("App mounted");
  },
  created(){
    // // 从localStorage中获取登录信息
    // const loginStatus = localStorage.getItem('loginStatus');
    // if (loginStatus) {
    //   const userData = JSON.parse(loginStatus);
    //   const dotData = {
    //     type: 'start',
    //     user: userData.data.name,
    //     tel: userData.data.mobile,
    //   };
    //   sendDot(dotData);
    // } else {
    //   //
    //   sendDot({
    //     type:'start',
    //     user:'',
    //     tel:''
    //   });
    // }
    
    // console.log("start from " + this.$route.fullPath);
    // // 检查登录状态
    // if (loginStatus) {
    //   // 用户已登录，跳转到论文编写页或其他需要的页面
    //   console.log("has login");
    //   // this.$router.push('/main');
    // } else {
    //   // 用户未登录，跳转到登录页
    //   console.log("not login");
    //   this.$router.push('/login');
    // }
  }
}
</script>

<style>
/* 样式 */
#app {
  max-width: 100%;
  /* margin: 0 auto;
  padding: 20px; */
  /* background-color: #2D3436; */
}
body {
  margin: 0;
  padding: 0;
}
</style>
