import { createStore } from 'vuex'

const store = createStore({
    state: {
        // 定义一个name，以供全局使用
        accessKey: '',
        sourceToken: '',
        loginObject:'',
        errorMessage:'',
    },
    getters: {
        getAccessKey(state) {
            return state.accessKey;
        },
        getSourceToken(state) {
            return state.sourceToken;
        },
        isScriptGenius(state){
            return state.sourceToken != null && state.sourceToken.length > 0;
        },
        getLoginObject(state){
            return state.loginObject;
        }
    },
    mutations: {
        setAccessKey(state, ak) {
            state.accessKey = ak;
        },
        setSourceToken(state, token){
            state.sourceToken = token;
        },
        setLoginObject(state, obj){
            state.loginObject = obj;
        },
        resetLoginObject(state){
            state.loginObject = null;
        },
        setErrorMessage(state, message) {
            state.errorMessage = message;
        },
    },
});

export default store;
