import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { ElInput } from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/css/global.css'
import store from './store';
// import VConsole from 'vconsole';
const app = createApp(App)

// 只在开发环境引入 VConsole
// if (process.env.NODE_ENV === 'development') {   
//     const vConsole = new VConsole({ // 配置选项 
//         defaultPlugins: ['system', 'network', 'element'], 
//         // 默认开启的插件 
//         onReady() { 
//             console.log('vConsole is ready!'); 
//         }, 
//     });
//     app.use(vConsole);
// }

app.use(router)
app.use(store)
app.use(ElInput)
app.mount('#app')