import { createRouter, createWebHistory } from 'vue-router';

// 导入组件
// import UserLogin from '../views/UserLogin.vue';
// import PaymentV from '../views/PaymentV.vue';
// import ReferenceDownloads from '../views/ReferenceDownloads.vue';


// import MainPage from '../views/MainPage.vue'
// import MainSidebar from '../views/MainSidebar'
// import MainContent from '../views/MainContent'

// import UserRegister from '../views/UserRegister'
// import SplashScreen from '../views/SplashScreen.vue'
// import ErrorPage from '../views/ErrorPage.vue'

// import ArticleCreation from '../views/ArticleCreation.vue'
// import ModificationItem from '../views/ModificationItem.vue'
// import DuplicationReduction from '../views/DuplicateReduction.vue'


// 创建路由实例
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path:'/',
      redirect:'splash'
    },
    {
      path: '/login',
      component: ()=>import('../views/UserLogin.vue'),
    },
    // {
    //   path: '/register',
    //   component: UserRegister,
    // },
    // {
    //   path: '/pay',
    //   component: PaymentV,
    // },
    {
      path: '/ref',
      component: ()=>import('../views/ReferenceDownloads.vue'),
    },
    {
      path: '/main',
      component: ()=>import('../views/MainPage.vue'),
      // children: [
      //   {
      //     path: "",
      //     components: {
      //       mainSidebar: MainSidebar,
      //       mainContent: MainContent,
      //     }
      //   }
      // ]
    },
    {
      path:'/splash',
      component:()=>import('../views/SplashScreen.vue'),
    },
    {
      path:'/err',
      name:'error',
      component:()=>import('../views/ErrorPage.vue'),
      props: true,
    },
  ],
});

router.beforeEach((to, from, next) => {
  // 在每次路由切换之前执行
  console.log('beforeEach ==> ' + from.path + ' --> ' + to.path);
  console.log(from);
  console.log(to);
  document.title = to.meta.title || 'AI智写助手'
  next()
})
router.afterEach((to, from) => {
    console.log('afterEach ==> ' + from.path + ' --> ' + to.path);
})

export default router;
